<template>
  <quill-editor ref="myTextEditor"
                v-model="pages.content"
                :config="editorOption">
  </quill-editor>
</template>

<style>
  .ql-snow .ql-tooltip{
    z-index: 10000 !important;
  }
</style>
<script>

  import { quillEditor } from 'vue-quill-editor'
  import 'quill/dist/quill.core.css'
  import 'quill/dist/quill.snow.css'
  import 'quill/dist/quill.bubble.css'

  export default {
    components: { quillEditor },
    props: ["pages"],
    data: () => ({
      editorOption: {},
    })
  };
</script>
